import React from "react";
import { CardBox, Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import { useLocation } from "react-router-dom";

const Message = () => {
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/messages${search}`,
    "admin/messages"
  );
  return (
    <>
      <Title title="Messages" />
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row>
          {data?.data.data.data.map((e) => (
            <Col lg={4} md={6} key={e.id}>
              <CardBox showEdit={true} deleteClick={() => deleteItem(e)}>
                <div className="text-xl text-start pb-3 ">
                  <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Pink">
                    Name :
                  </span>
                  <span> {e.name}</span>
                </div>
                <div className="text-xl text-start pb-3">
                  <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Pink">
                    Phone:
                  </span>
                  <span> {e.phone_number}</span>
                </div>
                <div className="text-xl text-start pb-3">
                  <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Pink">
                    Message :
                  </span>
                  <span>{e.message}</span>
                </div>
                <div className="text-xl text-start pb-3">
                  <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Pink">
                    Date :
                  </span>
                  <span>{e.created_at}</span>
                </div>
              </CardBox>
            </Col>
          ))}
        </Row>
      </Pagination>
    </>
  );
};

export default Message;
