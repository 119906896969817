import React from "react";
import ImageSlider from "./Sections/ImageSlider";
const Home = () => {
  return (
    <div>
      <ImageSlider />
    </div>
  );
};

export default Home;
